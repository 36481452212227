
import { Component, Prop, Emit, Inject } from "vue-property-decorator"
import JobRoleSelect from "@/components/widgets/input/JobRoleSelect.vue"
import SortableList from "@/components/widgets/sortable/SortableList.vue"
import SortableItem from "@/components/widgets/sortable/SortableItem.vue"
import SortableHandle from "@/components/widgets/sortable/SortableHandle.vue"
import {
  AddMissionScopeStaffMutation,
  AddMissionScopePhaseMutation,
  DeleteMissionScopePhaseMutation,
  AddMissionScopeActivityMutation,
  DeleteMissionScopeStaffMutation,
  UpdateMissionScopePhaseMutation,
  DeleteMissionScopeActivityMutation,
  UpdateMissionScopeActivityMutation,
  AddMissionScopeStaffMutationMutation,
  DeleteMissionScopeStaffMutationMutation,
  AddMissionScopeActivityMutationMutation,
  UpdateMissionScopeActivityMutationMutation,
  DeleteMissionScopeActivityMutationMutation,
  MissionLead,
  UpdateMissionScopeMutation,
} from "@/gql"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import MissionScopeOverview from "@/components/missions/MissionScopeOverview.vue"
import MissionPricing, { Activity, MissionScopeJobTitle, Phase } from "@/mixins/MissionPricing2"
import { mixins } from "vue-class-component"
import { State } from "vuex-class"
import { Namespaces } from "@/constants"
import Loader from "../widgets/common/Loader.vue"

export enum ClipboardKey {
  capacityValues = "capacity_values",
}

export type MarkupDoubleType = Record<"A" | "B" | "C", number[]>

@Component({
  components: {
    SortableList,
    SortableItem,
    JobRoleSelect,
    SortableHandle,
    MissionScopeOverview,
    Loader,
  },
})
export default class MissionScopeEditor extends mixins(MissionPricing) {
  @Prop({ required: true }) readonly section!: string
  @Prop() readonly value!: boolean
  @Prop() readonly missionLead!: MissionLead
  @Prop() readonly widget?: boolean

  @Inject()
  refetchQueries!: RefetchQueryDescription

  @State("loading", { namespace: Namespaces.MissionLifecycle })
  loadingMission!: boolean

  activeRole: MissionScopeJobTitle | null = null
  activeActivity: Activity | null = null
  activeActivityIndex: number | null = null
  activePhase: { [key: string]: any } | null = null

  showAddRoleModal = false
  showRemoveRoleDialog = false
  showRemoveActivityDialog = false
  showRemovePhaseDialog = false
  loading = false
  savingRole = false
  saving = false
  forceDelete = false
  showRoleFeesModal = false

  saved = false
  next = "Next"
  back = "Back"
  automateCap = true

  timeouts: { [key: string]: number } = {}
  clipboard: { [key: string]: any } = {}

  satisfactionScoreMap = {
    min: 0.1,
    max: 0.2,
    intermediate: 0.15,
  }

  markupDoubles: MarkupDoubleType = {
    A: [0.4, 0.45],
    B: [0.5, 0.55],
    C: [0.3, 0.35],
  }

  get minPartnerValue() {
    const type = this.missionLead?.client?.clientType.name
    const clientType = ["Unknown", "Company", "X", undefined, null].includes(type) ? "A" : type

    return Math.round(
      this.satisfactionScoreMap.min *
        this.netValue *
        (1 + this.markupDoubles[clientType as keyof MarkupDoubleType][0])
    )
  }

  get maxPartnerValue() {
    return Math.round(
      this.satisfactionScoreMap.max * this.netValue * (1 + this.markupDoubles["A"][1])
    )
  }

  get cols() {
    // adjust columns
    let expand = 0
    if (this.requiredRoles.length > 4 && this.requiredRoles.length < 7)
      expand = 12 - this.requiredRoles.length
    else expand = 12 - (this.requiredRoles.length + 2)

    return expand < 5 ? 5 : expand
  }

  get capacityCols() {
    return 12 - (this.cols + 2)
  }

  get displayPhases(): Phase[] {
    return this.phases.filter((p) => p.id)
  }

  get displayRequiredRoles() {
    // group required roles by abbr
    return this.requiredRoles.reduce((acc, r) => {
      if (!acc[r.abbr]) acc[r.abbr] = []
      acc[r.abbr].push(r)
      return acc
    }, {} as { [key: string]: MissionScopeJobTitle[] })
  }

  phaseMenu(phase: { [key: string]: any }, phaseIndex: number) {
    let menu: { [key: string]: () => void } = {
      Remove: () => {
        if (phase.id) {
          this.showRemovePhaseDialog = true
          this.activePhase = phase
        } else {
          this.removePhase(phase, phaseIndex)
        }
      },
    }

    const updatePhase = async (params: { [key: string]: any }) => {
      await this.onPhaseChange(phaseIndex, { ...params }, true)
      setTimeout(() => {
        this.calloutSaved(this.phases[phaseIndex], "scopePhaseSort")
      }, 300)
    }

    if (phaseIndex !== 0) {
      menu["Move up"] = async () => await updatePhase({ position: phaseIndex - 1 })
    }

    if (phaseIndex !== this.phases.length - 1) {
      menu["Move down"] = async () => await updatePhase({ position: phaseIndex + 1 })
    }

    return menu
  }

  calloutSaved(target: { [key: string]: any }, timeoutKey: string) {
    if (this.totalCapacityDistribution > 100) {
      return
    }

    this.$set(target, "saved", true)

    clearTimeout(this.timeouts[timeoutKey])

    this.timeouts[timeoutKey] = window.setTimeout(() => {
      this.$set(target, "saved", false)
    }, 3000)
  }

  mounted() {
    this.$nextTick(() => {
      if (
        this.$refs.editorContainer &&
        (this.$refs.editorContainer as HTMLElement).offsetWidth < 1000
      ) {
        this.resizeContainer()
      }
    })
  }

  resizeContainer() {
    const editorWrapper = this.$refs.editorWrapper as HTMLElement
    editorWrapper.style.width = "1200px"
  }

  validNumber(input: string): boolean {
    return input.toString().trim() == "" || !isNaN(parseFloat(input))
  }

  maybeUpdateCapacity(id: string, input: string) {
    if (this.validNumber(input)) this.onCapactityChange(id, 0)
  }

  get roleActions() {
    if (this.activeRole == undefined) return {}

    let actions: { [key: string]: () => void } = {
      Remove: () => {
        this.showRemoveRoleDialog = true
      },
    }

    if (this.can("view_mission_finances")) {
      actions["Fees"] = () => {
        this.showRoleFeesModal = true
      }
    }

    return actions
  }

  // [index: phaseId][index: roleIndex]
  get phaseCapacityTotals(): number[][] {
    // returns an array of capacity totals with phase id as index
    const phaseTotals = this.phases.reduce((mapped, phase) => {
      const activityIds = phase.activities.map((a) => Number(a.id))

      mapped[phase.id!] = this.editScopeForm.capacities.map((roleCapacities) => {
        return (
          roleCapacities.length &&
          parseFloat(
            // sum of role capacities per phase
            roleCapacities
              .filter(
                (c, activityId) =>
                  parseFloat(c as unknown as string) && activityIds.includes(activityId)
              )
              .map((c) => ((c as unknown as string) == "" ? 0 : c)) // convert <empty string> capacity values to 0
              .reduce((acc, cur) => acc + cur, 0)
              .toFixed(2)
          )
        )
      })
      return mapped
    }, [] as number[][])

    return phaseTotals
  }

  // [index: roleIndex]
  get capacityTotals(): number[] {
    return this.requiredRoles.map((role, index) => {
      return this.phaseCapacityTotals.reduce((sum, phase) => {
        return sum + phase[index]
      }, 0)
    })
  }

  get totalCapacity(): string {
    return this.capacityTotals.reduce((sum, cur) => sum + cur, 0).toFixed(1)
  }

  get capactityDistributionTotals(): { [phaseId: number]: number } {
    return Object.keys(this.editScopeForm.capacityDistribution).reduce((totals, phaseId) => {
      totals[phaseId as any] = Object.values(
        this.editScopeForm.capacityDistribution[phaseId as any]
      ).reduce((sum, value) => sum + Number(value), 0)
      return totals
    }, {} as { [phaseId: number]: number })
  }

  get totalCapacityDistribution(): number {
    return Object.values(this.capactityDistributionTotals).reduce(
      (sum, value) => sum + Number(value),
      0
    )
  }

  updateCapacityDistribution(phaseId: number, activityId: string, activityIndex: number) {
    const capacityDistribution = this.editScopeForm.capacityDistribution[phaseId][activityId]

    if (capacityDistribution >= 0) {
      this.onActivityChange(
        phaseId,
        activityId,
        {
          capacityDistribution: capacityDistribution,
        },
        activityIndex
      )
    }
  }

  async onAddRole() {
    // create
    this.emitSaving()

    let errors = false

    for (let i = 0; i < this.editScopeForm.role.number; i++) {
      this.savingRole = true

      const result = await this.mutate<AddMissionScopeStaffMutationMutation>({
        mutation: AddMissionScopeStaffMutation,
        variables: {
          missionScopeId: this.missionScope!.id,
          jobTitleId: this.editScopeForm.role.jobTitle!.id,
        },
        refetchQueries: this.refetchQueries,
      })

      if (result.data?.addMissionScopeStaff.staff) {
        // success
        this.requiredRoles.push({
          id: result.data.addMissionScopeStaff.staff.id,
          abbr: this.editScopeForm.role.jobTitle!.abbreviation!,
          rate: this.editScopeForm.role.jobTitle!.dailyRate!,
          capacityFactor: this.editScopeForm.role.jobTitle!.capacityFactor!,
        })

        // initialize capacities
        this.editScopeForm.capacities.push([])
        this.savingRole = false
        if (this.requiredRoles.length > 8) this.resizeContainer()
      } else {
        errors = true
      }
    }

    if (!errors) {
      this.editScopeForm.role.jobTitle = null
      this.showAddRoleModal = false

      this.$refs.roleObserver && (this.$refs.roleObserver as any).reset()
      this.editScopeForm.role.number = 1 // set required number back to 1

      this.emitSaved()
    }
  }

  onAddPhase() {
    if (this.phases.filter((p) => !p.id).length < 5)
      this.phases.push({ name: "", activities: [], saving: false, saved: false })
    else this.addInfo("Please fill in empty phases")
  }

  onAddActivity(id: number) {
    const phase = this.phases.find((p) => p.id === id)

    if (phase && phase.id) {
      const placeholderId = this.generateRand(5) as any as number
      phase.activities!.push({
        id: placeholderId,
        prn: "",
        name: "",
        missionScopePhaseId: id,
        key: this.generateRand(4), // used to force re-render
        saved: false,
        capacityDistribution: 0,
      })

      this.$set(this.editScopeForm.capacityDistribution[phase.id], placeholderId, 0)
    }

    window.setTimeout(() => {
      const input = document.querySelector(`.activity-input-${this.activities.length - 1} input`)
      // focus last activity input
      input && (input as HTMLElement).focus()
    }, 200)
  }

  async removeActivity(activity: Activity) {
    const removeItems = () => {
      const phase = this.phases.find((p) => p.id === activity.missionScopePhaseId)!
      // remove activity from phase
      phase.activities.splice(this.activeActivityIndex!, 1)

      // remove activity from form
      this.editScopeForm.activities[activity.missionScopePhaseId].splice(
        this.activeActivityIndex!,
        1
      )

      // remove capacities from form
      this.editScopeForm.capacities.forEach((col) => {
        col.splice(activity.id!, 1)
      })

      // reset vars
      this.showRemoveActivityDialog = false
      this.activeActivity = null
      this.activeActivityIndex = null
    }

    if (!activity.saved) {
      removeItems() // Remove empty activity
      return
    }

    this.loading = true

    this.emitSaving()
    const result = await this.mutate<DeleteMissionScopeActivityMutationMutation>({
      mutation: DeleteMissionScopeActivityMutation,
      variables: {
        activityId: activity.id,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.loading = false
      },
    })

    if (result.data && !result.data.deleteMissionScopeActivity.error) {
      removeItems()
      this.emitSaved()
    }
  }

  get activeRoleIndex(): number {
    return this.requiredRoles.findIndex((r) => r.id === this.activeRole!.id)
  }

  async removeRole(roleToRemove: MissionScopeJobTitle) {
    this.loading = true
    this.emitSaving()

    const result = await this.mutate<DeleteMissionScopeStaffMutationMutation>({
      mutation: DeleteMissionScopeStaffMutation,
      variables: { missionScopeStaffId: roleToRemove.id },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.loading = false
      },
    })

    if (result.data && !result.data.deleteMissionScopeStaff.error) {
      const index = this.requiredRoles.findIndex((r) => r.id === roleToRemove.id)
      // remove from roles state
      this.requiredRoles.splice(index, 1)
      // remove capacities from form
      this.editScopeForm.capacities.splice(index, 1)
      this.showRemoveRoleDialog = false

      this.showRemoveRoleDialog = false
      this.activeRole = null
      this.emitSaved()
    }
  }

  async removePhase(phase: { [key: string]: any } | null, phaseIndex?: number) {
    if (!phase) return
    const removeItems = () => {
      this.phases.splice(
        phase?.id ? this.phases.findIndex((p) => p.id === phase.id) : phaseIndex!,
        1
      )
    }

    if (!phase?.id) {
      return removeItems() // Remove empty phase
    }

    this.loading = true
    this.$set(phase, "saving", true)
    this.emitSaving()

    // mutate
    const result = await this.mutate({
      mutation: DeleteMissionScopePhaseMutation,
      variables: {
        id: phase.id,
        forceDelete: this.forceDelete,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.loading = false
        this.showRemovePhaseDialog = true
      },
      error: (error) => {
        if (error.code && error.code == "105") {
          this.forceDelete = true
          this.showRemovePhaseDialog = false
        }
      },
    })

    if (result.data && !result.data.deleteMissionScopePhase.error) {
      removeItems()
      this.showRemovePhaseDialog = false
      this.forceDelete = false
      this.emitSaved()
    }
  }

  onActivitySort(phase: Phase, { newIndex }: { [key: string]: any }) {
    const activities = phase.activities

    this.onActivityChange(phase.id!, activities![newIndex].prn!, {
      position: newIndex,
    })
  }

  async onPhaseChange(phaseIndex: number, variables: { [key: string]: any }, refetch?: boolean) {
    const phase = this.phases[phaseIndex]

    // If new phase and name is undefined
    if (!phase.id && !variables?.name) {
      return
    }

    this.emitSaving()
    this.$set(this.phases[phaseIndex], "saving", true)

    if (phase.id) {
      // update
      const result = await this.mutate({
        mutation: UpdateMissionScopePhaseMutation,
        variables: {
          phaseId: phase.id,
          ...variables,
        },
        refetchQueries: refetch ? this.refetchQueries : undefined,
      })

      if (result.data && !result.data.updateMissionScopePhase.error) {
        this.$set(this.phases[phaseIndex], "saving", false)
        this.emitSaved()
      }
    } else {
      //create
      const result = await this.mutate({
        mutation: AddMissionScopePhaseMutation,
        variables: {
          missionScopeId: this.missionScope.id,
          ...variables,
        },
      })

      if (result.data?.addMissionScopePhase?.missionScopePhase) {
        phase.id = result.data.addMissionScopePhase.missionScopePhase.id
        phase.name = this.editScopeForm.phases[`idx:${phaseIndex}` as any]!

        this.editScopeForm.activities[phase.id!] = []

        // Initialize phase
        this.syncPhaseWithPhaseInput(phase.id!, phaseIndex)
        this.$set(this.phases[phaseIndex], "saving", false)
        this.editScopeForm.capacityDistribution[phase.id!] = {}

        this.onAddActivity(phase.id!)

        this.emitSaved()
      }
    }
  }

  async onActivityChange(
    phaseId: number,
    activityId: string,
    variables: { [key: string]: any },
    activityIndex?: number,
    refetch?: boolean
  ) {
    // If new activity and name is undefined
    if (!activityId && !variables?.name) {
      return
    }

    if (this.totalCapacityDistribution > 100) {
      return
    }

    this.emitSaving()

    const phase = this.phases.find((p) => p.id === phaseId)!
    const activity = phase.activities.find((a) => a.prn === activityId)

    if (activity?.saved) {
      // UPDATE
      const result = await this.mutate<UpdateMissionScopeActivityMutationMutation>({
        mutation: UpdateMissionScopeActivityMutation,
        variables: {
          missionScopeActivityId: activityId,
          ...variables,
        },
        refetchQueries: refetch ? this.refetchQueries : undefined,
      })

      if (result.data && !result.data.updateMissionScopeActivity.error) this.emitSaved()
      return
    }

    // CREATE
    const result = await this.mutate<AddMissionScopeActivityMutationMutation>({
      mutation: AddMissionScopeActivityMutation,
      variables: {
        missionScopeId: this.missionScope.id,
        missionScopePhaseId: phaseId,
        ...variables,
      },
      refetchQueries: refetch ? this.refetchQueries : undefined,
    })

    if (result.data?.addMissionScopeActivity.activity) {
      const phase = this.phases.find((p) => p.id === phaseId)!

      if (activityIndex != undefined) {
        this.$set(
          phase.activities[activityIndex],
          "id",
          result.data.addMissionScopeActivity.activity.id
        )

        this.$set(activity!, "saved", true)
        this.initializeActivity(phase, result.data.addMissionScopeActivity.activity.id)
      }

      this.emitSaved()
    }
  }

  initializeActivity(phase: Phase, id: number | string) {
    this.$set(this.editScopeForm.capacityDistribution[phase.id!], id, 0)
  }

  async onCapactityChange(activityId: string, phaseId: number) {
    const json = this.serializeCapacities(activityId)
    await this.onActivityChange(phaseId, activityId, {
      capacities: json,
    })
  }

  serializeCapacities(activityId: string) {
    const obj: { [key: string]: number } = {}

    this.requiredRoles.forEach((role, roleIndex) => {
      const value = this.editScopeForm.capacities[roleIndex][activityId as any]
      return (obj[role.id] = value)
    })

    return JSON.stringify(obj)
  }

  onDirectionalKeyDown(
    type: string,
    direction: string,
    phaseIndex: number,
    activityIndex: number,
    roleIndex?: number
  ) {
    // autofocus next/previous input in vertical direction
    const index = direction === "up" ? activityIndex - 1 : activityIndex + 1
    const input =
      type === "capacity"
        ? document.querySelector(`.capacity-input-${phaseIndex}-${roleIndex}-${index} input`)
        : document.querySelector(`.activity-input-${phaseIndex}-${index} input`)

    input && (input as HTMLElement).focus()
  }

  /**
   * Phase inputs are v-modeled by phase.id OR 0,
   * this method is invoked because when a new phase is created a blank activity is added to it,
   * this triggers a re-render with makes v-model of newly created phase point to undefined
   */
  syncPhaseWithPhaseInput(id: number, phaseIndex: number) {
    this.editScopeForm.phases[id] = this.editScopeForm.phases[`idx:${phaseIndex}` as any]!
    this.editScopeForm.phases[`idx:${phaseIndex}` as any] = undefined
  }

  copyValuesToClipboard(key: string, values: any) {
    this.clipboard[key] = values
  }

  async onScopeMetaChange() {
    this.emitSaving()

    const result = await this.mutate({
      mutation: UpdateMissionScopeMutation,
      variables: {
        id: this.missionScope.id,
        targetMissionDuration: this.editScopeForm.targetMissionDuration,
        capacityAllocation: this.editScopeForm.capacityAllocation,
      },
    })

    if (result.data && !result.data.updateMissionScope.error) {
      this.emitSaved()
    }
  }

  @Emit("save")
  emitSaved() {
    return true
  }

  @Emit("saving")
  emitSaving() {
    return true
  }
}
